<template>
  <!-- 导航 -->
  <div style="background: #f1f1f1; height: 100%; box-sizing: border-box;">
    <van-nav-bar
      title="确认订单"
      left-text
      left-arrow
      @click-left="$router.back(-1)"
    >
      <template #right></template>
    </van-nav-bar>
    <!-- 导航 -->

    <!-- 收货地址 -->
    <div @click="$router.push('/shippingAddress')" style="margin-top: 4px;">
      <div
        v-if="address == ''"
        style="
          display: flex;
          padding: 15px 15px;
          align-items: center;
          background-color: #ffffff;
          flex-direction: column;
        "
      >
        <div>
          <van-icon size="20px" name="add-o" />
        </div>
        <div>点击添加收货地址</div>
      </div>
      <div v-if="address != ''">
        <van-cell is-link center>
          <!-- 使用 title 插槽来自定义标题 -->
          <template #title>
            <span class="title">{{ address.userName }}</span>
            <span class="title" style="margin-left: 6px">{{
              address.telNum
            }}</span>
            <van-tag
              color="#FF0038"
              text-color="#ffffff"
              v-show="address.isDefault == 1"
              style="margin-left: 8px"
              >默认</van-tag>
            <!-- <van-tag color="#3E85FF" text-color="#ffffff">家</van-tag> -->
          </template>
          <!-- 使用 label 插槽来自定义描述 -->
          <template #label>
            <span class="label">{{
              address.provinceName +
              address.cityName +
              address.countyName +
              address.detailInfo
            }}</span>
          </template>
        </van-cell>
      </div>
    </div>
    <!-- 收货地址 -->

		<div class="container">
			<div class="container-shop" v-for="item in this.goods" :key="item.id">
				<img style="width: 80px; height: 80px;" :src="item.picUrl" alt="">
				<div class="container-shop-info">
					<p class="css">
						<span>{{ item.spuName }}</span>
						<span style="color: #a80000;">￥{{ item.addPrice }}</span>
					</p>
					<p style="color: #b2b2b2;">{{ item.specInfo }}</p>
					<p style="color: #b2b2b2;" class="css">
						<span>运费：￥{{ item.freightPrice }}</span>
						<span style="color: #000;">*{{ item.quantity }}</span>
					</p>
				</div>
			</div>

			<p class="peisongType">配送方式  普通快递</p>
			<p>商家备注</p>
			<van-field
        v-model="remark"
        rows="1"
        autosize
        type="textarea"
        placeholder="选填 请先和商家协商一致"
        style="padding: 10px 0"
      />
			<p style="text-align: right;">共{{ quantity }}件商品小计： <span style="color: #a80000;">￥{{ totalPrice }}</span></p>
		</div>

		<div class="pay">
			<van-cell title="商品总额" :value="`￥${totalPrice}`" />
			<van-cell title="运费" :value="`￥${yunfei}`" />
			<div class="pay-bottom">
				<van-radio-group v-model="paymentType">
					<van-cell-group inset>
						<van-cell title="单选框 1" clickable @click="paymentType = '2'">
							<template #title>
								<img class="img" src="../../assets/img/alipay-icon.png" alt />
								<span class="custom-title">支付宝</span>
							</template>
							<template #right-icon>
								<van-radio name="2" />
							</template>
						</van-cell>
						<!-- <van-cell title="单选框 2" clickable @click="paymentType = '1'">
							<template #title>
								<img class="img" src="../../assets/img/weChat-icon.png" alt />
								<span class="custom-title">微信</span>
							</template>
							<template #right-icon>
								<van-radio name="1" />
							</template>
						</van-cell> -->
					</van-cell-group>
				</van-radio-group>
			</div>
		</div>

		<van-submit-bar
			:price="(Number(totalPrice) + Number(yunfei)) * 100"
			button-text="提交订单"
			@submit="onSubmit"
		/>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { getPage } from "@/api/shoppingAddress/shoppingAddress"
import { submitOrder, submitAlipay, submitWxpay, } from "@/api/sureOrder/sureOrder"
let backAddress = ""
export default {
  data() {
    return {
      address: "",
			paymentType: '',
      type: null
    }
  },

  computed: {
    ...mapState({
      goods: state => JSON.parse(JSON.stringify(state.tsMall.data))
    }),

    remark: {
      get () {
        return this.$store.state.tsMall.remark
      },
      set (val) {
        this['tsMall/set_remark'](val)
      }
    },

    totalPrice () {
      return this.$store.getters['tsMall/totalPrice']
    },

    quantity () {
      return this.$store.getters['tsMall/quantity']
    },

    yunfei () {
      return this.$store.getters['tsMall/yunfei']
    },

    skus () {
      return this.$store.getters['tsMall/skus']
    }
  },

	beforeRouteEnter(to, from, next) {
    if (from.name === "ShippingAddress") {
      if (from.params.address) {
				backAddress = from.params.address;
			}
    } else {
      backAddress = "";
    }
    next();
  },

	created () {
		if (backAddress == "" || !backAddress) {
      if (
        this.$route.query.address != null &&
        this.$route.query.address != ""
      ) {
        if (JSON.parse(this.$route.query.address) != "") {
          this.address = JSON.parse(this.$route.query.address);
        } else {
          this.getPage();
        }
      } else {
        this.getPage();
      }
    } else {
      this.address = backAddress;
    }

    if (this.$route.query.type) {
      this.type = this.$route.query.type;
    }
	},

	methods: {
    ...mapMutations(['tsMall/set_remark']),

		getPage() {
      getPage({ current: 1, size: 10 }).then((res) => {
        if (res.data.code === 0) {
          if (res.data.data.records.length == 0) {
            this.address = "";
          } else {
            this.address = res.data.data.records[0];
          }
        }
      });
    },

    input (remark) {
      this['tsMall/set_remark'](remark)
    },

		onSubmit () {
      if (this.address == "") {
        this.$toast('请选择地址')
        return
      }
      if (this.paymentType != '1' && this.paymentType != '2') {
        this.$toast('请选择支付方式')
        return
      }
      let appType = "";
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //androi
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        appType = "ios";
      }
      if (isAndroid) {
        appType = "Android";
      }
      let formInline = {
        paymentWay: '2',
        deliveryWay: '1',
        paymentType: this.paymentType,
        userMessage: this.remark,
        appType: appType,
        appId: null, // 应用ID
        userAddressId: this.address.id, // 用户收货地址ID
        orderType: '0',
        relationId: null,
        visitTime: null,
        type: this.type,
        userId: this.$store.state.session,
        skus: this.skus
      }
      submitOrder(formInline).then(res => {
        if (res.data.code === 0) {
          let ids = []
          res.data.data.forEach(x => {
            ids.push(x.id)
          })
          if (this.paymentType == '2') {
            this.submitAlipay(ids)
          } else {
            this.submitWxpay(ids)
          }
        } else {
          return res.data.message
        }
      })
		},

    //原生支付宝支付
    submitAlipay(form) {
      submitAlipay({ ids: form, tradeType: "APP" }).then((res) => {
        // 后面改了等于0
        if (res.data.data === null) {
          this.$goBack();
          return;
        }
        if (res.data.code === 0) {
          // Toast.success("下单成功");
          try {
            window.webkit.messageHandlers.alipay.postMessage(res.data.data);
          } catch {
            window.android.alipay(res.data.data);
          }
        }
      });
    },

    //原生微信支付
    submitWxpay(id) {
      submitWxpay({ ids: id, tradeType: "APP" }).then((res) => {
        if (res.data.data === null) {
          this.$toast.success("下单成功");
          this.$goBack();
          return;
        }
        try {
          window.webkit.messageHandlers.wechatPay.postMessage(
            JSON.stringify(res.data.data)
          );
        } catch {
          window.android.wxpay(
            res.data.data.appId,
            res.data.data.partnerId,
            res.data.data.prepayId,
            res.data.data.nonceStr,
            res.data.data.timeStamp,
            res.data.data.packageValue,
            res.data.data.sign
          );
        }
      });
    },
	}
}
</script>

<style lang="less" scoped>
.container {
	font-size: 14px;
	padding: 15px;
	background: #FFF;
	margin-top: 10px;
	&-shop {
		// border: 1px solid red;
    margin-bottom: 10px;
		display: flex;
		&-info {
			flex: 1;
			.css {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
			margin-left: 8px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}
	}
	.peisongType {
		margin: 10px 0;
	}
}

.pay {
	margin-top: 10px;
	padding-bottom: 60px;
	background: #fff;
	&-bottom {
		.van-cell {
			padding: 10px 0;
		}
	}
	.img {
		width: 25px;
		height: 25px;
		margin-right: 10px;
		vertical-align: middle;
    box-sizing: border-box;
	}
}
</style>